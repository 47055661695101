
import Vue from "vue"
import axios from "axios"
import Saisirdate from "../components/SaisirDate.vue"
import ShemasPneu from "../components/ShemasPneu.vue"
import NonAcces from "../components/NonAcces.vue"
import { DemandeMaintenance, Machine, Pneu, ChangementPneu, Utilisateur } from "@/store/types"
import { dateFrancaise, dateFromString, dateVersChaine, sqlDate, variables } from "@/plugins/variables"

export default Vue.extend({
  name: "Maintenance",
  components: { Saisirdate, NonAcces, ShemasPneu },
  data: () => ({
    unsubscribe: null as any,
    listeMaintenances: [] as DemandeMaintenance[],
    demandeDeMaintenace: [] as DemandeMaintenance[],
    enCourMaintenance: [] as DemandeMaintenance[],
    demandeTerminer: [] as DemandeMaintenance[],
    uneDemandeDeMaintenace: new DemandeMaintenance(),
    lesDemandeATerminer: [] as string[],
    modeTerminer: false,
    machines: [] as Machine[],
    utilisateurs: [] as Utilisateur[],
    selectUtilisateur: [] as any[],
    selectPriorite: [] as any[],
    selectEtatDemande: [] as any[],
    selectPneuDemonte: [] as any[],
    selectPneuMonte: [] as any[],
    gestionPneu: [
      { pneu: new Pneu(), demonter: "" },
      { pneu: new Pneu(), monter: "" }
    ] as any[],
    pneus: [] as Pneu[],
    lesPneuMachine: [] as Pneu[],
    lesPneuMachineTampon: [] as Pneu[],
    pneusAMonter: [] as any[],
    changementPneu: [] as ChangementPneu[],
    triePrioriter: [false, false, false],
    trieDate: [false, false, false],
    trieMachine: [false, false, false],
    dialogueNouvelleMaintenance: false,
    dialogueTab: "",
    dialogueTabItems: [] as string[],
    emplacementPneu: "",
    lePremierJour: "2023-06-06",
    leDernierJour: "2023-06-06",
    snak_visible: false,
    snakbar_text: "",
    dictionnaire: [] as string[]
  }),
  methods: {
    initialisation() {
      axios
        .post(this.$store.state.ajaxurl + "Maintenance.php", {
          action: "INITIALISATION",
          lePremierJour: this.lePremierJour,
          leDernierJour: this.leDernierJour
        })
        .then(response => {
          if (response.data.listeMaintenances) {
            this.listeMaintenances = response.data.listeMaintenances.map((m: DemandeMaintenance) => {
              return new DemandeMaintenance(m)
            })
            this.demandeDeMaintenace = this.listeMaintenances.filter((d: DemandeMaintenance) => d.getEtat() == "D")
            this.enCourMaintenance = this.listeMaintenances.filter((d: DemandeMaintenance) => d.getEtat() == "C")
            this.demandeTerminer = this.listeMaintenances.filter((d: DemandeMaintenance) => d.getEtat() == "T")
          }
          if (response.data.machines) {
            this.machines = response.data.machines.map((m: Machine) => {
              return new Machine(m)
            })
          }
          if (response.data.utilisateurs) {
            this.utilisateurs = response.data.utilisateurs.map((u: Utilisateur) => {
              return new Utilisateur(u)
            })
            this.selectUtilisateur = response.data.utilisateurs.map((u: Utilisateur) => {
              return { text: u.ut_nom + " " + u.ut_prenom, value: u.ut_id }
            })
          }
          if (response.data.pneus) {
            this.pneus = response.data.pneus.map((p: Pneu) => {
              return new Pneu(p)
            })
          }
        })
    },
    ouvertureDialogueNouvelleMaintenan(sdm_id: string) {
      this.dialogueNouvelleMaintenance = true
      this.dialogueTab = this.dialogueTabItems[0]
      this.$store.commit("majPneu", { couleurFond: "#E0F7FA", etat: "SANS_PNEU" })
      if (sdm_id === undefined) {
        this.uneDemandeDeMaintenace = new DemandeMaintenance()
        this.uneDemandeDeMaintenace.iniMaintenance()
      } else {
        const uneDemande = this.listeMaintenances.find((d: DemandeMaintenance) => d.getId() == sdm_id)
        if (uneDemande !== undefined) {
          this.uneDemandeDeMaintenace = uneDemande
          const laMachine = this.machines.find((m: Machine) => m.getId() == this.uneDemandeDeMaintenace.getIDMachine())
          if (laMachine !== undefined && laMachine.getPneu().length != 0) {
            this.lesPneuMachine = laMachine.getPneu()
            this.lesPneuMachineTampon = laMachine.getPneu().map((p: Pneu) => {
              return new Pneu(p)
            })
            this.modificationGestionPneu(uneDemande.getMaintenances().getPneu())
          } else {
            this.lesPneuMachineTampon = []
          }
        } else {
          this.uneDemandeDeMaintenace.iniMaintenance()
          this.snackbarVisible(this.dictionnaire[21])
        }
      }
    },
    modificationGestionPneu(sma_pneu: string) {
      axios.post(this.$store.state.ajaxurl + "Maintenance.php", { action: "ALLER_CHERCHER_PNEU", sma_pneu: sma_pneu }).then(response => {
        if (response.data.gestionPneu) {
          for (const unPneu of this.lesPneuMachine) {
            const unPneuChanger = response.data.gestionPneu.filter((g: any) => g.sp_monteOu == unPneu.getMonteOu())
            if (unPneuChanger !== undefined && unPneuChanger.length == 2) {
              this.changementPneu.push(
                new ChangementPneu(
                  unPneuChanger[0].sp_monteOu,
                  unPneuChanger[0].sp_code,
                  unPneuChanger[0].sp_etat,
                  unPneuChanger[0].sp_id,
                  unPneuChanger[1].sp_code,
                  unPneuChanger[1].sp_etat,
                  unPneuChanger[1].sp_id
                )
              )
            }
          }
        }
      })
    },
    pneuAfficher() {
      const uneMachine = this.machines.find((m: Machine) => m.getId() == this.uneDemandeDeMaintenace.getIDMachine())
      if (uneMachine !== undefined) {
        if (uneMachine.getPneu().length != 0) {
          let emplacements = uneMachine.getPneu()[0].getMonteOu()
          for (let i = 1; i < uneMachine.getPneu().length; i++) {
            emplacements += " " + uneMachine.getPneu()[i].getMonteOu()
          }
          this.$store.commit("majPneu", { lesEmplacement: emplacements, etat: "AFFICHER_PNEU", couleurFond: "#E0F7FA" })
          this.changementPneu = []
          this.modificationGestionPneu(this.uneDemandeDeMaintenace.getMaintenances().getPneu())
        }
      } else {
        this.$store.commit("majPneu", { couleurFond: "#E0F7FA", etat: "SANS_PNEU" })
      }
    },
    enregistrerChangementPneu(emplacementPneu: string) {
      if (emplacementPneu != "" && emplacementPneu != "Aucun" && emplacementPneu != "Keine" && this.gestionPneu[1].pneu.sp_code != "") {
        const index = this.changementPneu.findIndex((c: ChangementPneu) => c.getEmplacementPneu() == emplacementPneu)
        const unChangement = new ChangementPneu(
          emplacementPneu,
          this.gestionPneu[0].pneu.sp_code,
          this.gestionPneu[0].demonter,
          this.gestionPneu[0].pneu.sp_id,
          this.gestionPneu[1].pneu.sp_code,
          this.gestionPneu[1].monter,
          this.gestionPneu[1].pneu.sp_id
        )
        if (index == -1) {
          this.changementPneu.push(unChangement)
        } else {
          this.changementPneu.splice(index, 1, unChangement)
        }
      }
    },
    verifierChangementPneu(emplacementPneu: string) {
      if (this.changementPneu.length != 0) {
        const leChangement = this.changementPneu.find((c: ChangementPneu) => c.getEmplacementPneu() == emplacementPneu)
        if (leChangement !== undefined) {
          const lePneuDemonter = this.pneus.find((p: Pneu) => p.getId() == leChangement.getIdPneuDemonter())
          if (lePneuDemonter !== undefined) {
            this.gestionPneu[0].pneu = lePneuDemonter
            this.gestionPneu[0].demonter = leChangement.getEtatPneuDemonter()
          }
          const lePneuMonter = this.pneus.find((p: Pneu) => p.getId() == leChangement.getIdPneuMonter())
          if (lePneuMonter !== undefined) {
            this.gestionPneu[1].pneu = lePneuMonter
            this.gestionPneu[1].monter = leChangement.getEtatPneuMonter()
          }
          return true
        }
      }
      return false
    },
    selectionnerPneuAMonte() {
      const lePneu = this.pneus.find((p: Pneu) => p.getCode() == this.gestionPneu[1].pneu.sp_code)
      if (lePneu !== undefined) {
        this.gestionPneu[1].monter = lePneu.getEtat()
      } else {
        this.gestionPneu[1].monter = ""
      }
    },
    async ongletSlectionner(onglet: string) {
      if (onglet == "Pneu") {
        await this.delay(1)
        this.pneuAfficher()
      }
    },
    machineSelectionner() {
      const laMachine = this.machines.find((m: Machine) => m.getId() == this.uneDemandeDeMaintenace.getIDMachine())
      if (laMachine !== undefined) {
        this.uneDemandeDeMaintenace.getMaintenances().setHorametre(laMachine.getHorametre())
        if (laMachine.getPneu().length != 0) this.lesPneuMachine = laMachine.getPneu()
        this.lesPneuMachineTampon = laMachine.getPneu().map((p: Pneu) => {
          return new Pneu(p)
        })
      } else {
        this.lesPneuMachineTampon = []
      }
    },
    pneuSelectionner(pneuStore: any) {
      if (pneuStore.etat == "SELECTIONNER_PNEU" && pneuStore.emplacmenentSelectionner != "") {
        this.enregistrerChangementPneu(this.emplacementPneu)
        this.emplacementPneu = pneuStore.emplacmenentSelectionner
        const verifPneu = this.verifierChangementPneu(this.emplacementPneu)
        if (verifPneu == false) {
          this.gestionPneu = [
            { pneu: new Pneu(), demonter: "" },
            { pneu: new Pneu(), monter: "" }
          ]
        }
        this.pneusAMonter = []
        const lePneu = this.pneus.find((p: Pneu) => p.getMonteOu() == pneuStore.emplacmenentSelectionner)
        if (lePneu !== undefined) {
          this.pneusAMonter.push({ value: lePneu, text: lePneu.getCode() })
          if (verifPneu == false) {
            this.gestionPneu[0].pneu = lePneu
            this.gestionPneu[0].demonter = lePneu.getEtat()
          }
        }
        for (const unPneu of this.pneus) {
          if ((unPneu.getMonteOu() == null || unPneu.getCode() == "") && unPneu.peutEtreMonter() == true && unPneu.getDimension() == lePneu?.getDimension()) {
            this.pneusAMonter.push({ value: unPneu, text: unPneu.getCode() })
          }
        }
        this.pneusAMonter.sort((a: any, b: any) => {
          if (a.value.sp_code > b.value.sp_code) return 1
          if (a.value.sp_code < b.value.sp_code) return -1
          return 0
        })
      } else {
        this.emplacementPneu = this.dictionnaire[15]
        this.pneusAMonter = []
        this.gestionPneu = [
          { pneu: new Pneu(), demonter: "" },
          { pneu: new Pneu(), monter: "" }
        ]
      }
    },
    fermerDialogueNouvelleMaintenance() {
      this.dialogueNouvelleMaintenance = false
      this.uneDemandeDeMaintenace = new DemandeMaintenance()
    },
    enregistrerUneDemandeDeMaintenance() {
      let modification = true
      this.pneuSelectionner(this.$store.state.pneu)
      if (this.uneDemandeDeMaintenace.getId() == "") {
        this.uneDemandeDeMaintenace.setId(new Date().getTime() + "")
        this.uneDemandeDeMaintenace.getMaintenances().setId(new Date().getTime() + "")
        if (this.uneDemandeDeMaintenace.getUtilisateur() == "") {
          this.uneDemandeDeMaintenace.setUtilisateur(this.uneDemandeDeMaintenace.getMaintenances().getUtilisateur())
        }
        if (this.uneDemandeDeMaintenace.getPanne() == "" && this.changementPneu.length != 0) {
          this.uneDemandeDeMaintenace.setPanne(this.dictionnaire[22])
        }
        this.demandeDeMaintenace.push(this.uneDemandeDeMaintenace)
        modification = false
      }
      this.enregistrerChangementPneu(this.emplacementPneu)
      axios
        .post(this.$store.state.ajaxurl + "Maintenance.php", {
          action: "ENREGISTRER_MAINTENANCE",
          uneDemandeDeMaintenance: this.uneDemandeDeMaintenace,
          lesPneuChanger: this.changementPneu,
          modification: modification
        })
        .then(responce => {
          if (responce.data.dm && responce.data.m) {
            this.uneDemandeDeMaintenace = new DemandeMaintenance()
            this.dialogueNouvelleMaintenance = false
            this.initialisation()
          } else {
            this.snackbarVisible(this.dictionnaire[20])
          }
        })
    },
    checkTerminer(sdm_id: string) {
      const existe = this.lesDemandeATerminer.findIndex((d: string) => d == sdm_id)
      if (existe == -1) {
        this.lesDemandeATerminer.push(sdm_id)
      } else {
        this.lesDemandeATerminer.splice(existe, 1)
      }
    },
    enregistrerTerminer() {
      axios
        .post(this.$store.state.ajaxurl + "Maintenance.php", {
          action: "ENREGISTRER_TERMINER",
          lesDemandeATerminer: this.lesDemandeATerminer
        })
        .then(() => {
          this.modeTerminer = false
          this.lesDemandeATerminer = []
          this.initialisation()
        })
    },
    chercherNomMachine(sm_id: number) {
      const uneMachine = this.machines.find((m: Machine) => m.getId() == sm_id)
      if (uneMachine !== undefined) {
        return uneMachine.getDesignation()
      }
      return ""
    },
    dateFrancaise(date: string) {
      return dateFrancaise(date)
    },
    premierJourSemaine(date: Date) {
      while (date.getDay() != 1) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 12)
      }
      return date
    },
    dernierJourSemaine(date: Date) {
      while (date.getDay() != 0) {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 12)
      }
      return date
    },
    snackbarVisible(text: string) {
      this.snakbar_text = text
      this.snak_visible = true
    },
    delay(n: number) {
      return new Promise(function(resolve) {
        setTimeout(resolve, n * 100)
      })
    },
    retournPrioriter(sdm_prioriter: string) {
      switch (sdm_prioriter) {
        case "F":
          return this.selectPriorite[0].text
        case "M":
          return this.selectPriorite[1].text
        case "E":
          return this.selectPriorite[2].text
        default:
          return "Error"
      }
    },
    changerSemaine(nbJour: number) {
      const lePremierJour = dateFromString(this.lePremierJour)
      const leDernierJour = dateFromString(this.leDernierJour)
      this.lePremierJour = dateVersChaine(new Date(lePremierJour.getFullYear(), lePremierJour.getMonth(), lePremierJour.getDate() + nbJour, 12))
      this.leDernierJour = dateVersChaine(new Date(leDernierJour.getFullYear(), leDernierJour.getMonth(), leDernierJour.getDate() + nbJour, 12))
      this.lePremierJour = sqlDate(this.premierJourSemaine(new Date(this.lePremierJour)))
      this.leDernierJour = sqlDate(this.dernierJourSemaine(new Date(this.leDernierJour)))
      this.initialisation()
    },
    trie(colonne: string, listeDemande: DemandeMaintenance[], quelListe: string) {
      let resultatTrie: DemandeMaintenance[] = []

      switch (colonne) {
        case "Prioriter":
          resultatTrie = this.trierPrioriter(listeDemande)
          break
        case "Date":
          resultatTrie = this.trierDate(listeDemande)
          break
        case "Machine":
          resultatTrie = this.trierMachine(listeDemande)
          break
      }
      switch (quelListe) {
        case "Demande":
          this.demandeDeMaintenace = resultatTrie
          switch (colonne) {
            case "Prioriter":
              this.triePrioriter[0] = true
              this.trieMachine[0] = false
              this.trieDate[0] = false
              break
            case "Date":
              this.triePrioriter[0] = false
              this.trieMachine[0] = false
              this.trieDate[0] = true
              break
            case "Machine":
              this.triePrioriter[0] = false
              this.trieMachine[0] = true
              this.trieDate[0] = false
              break
          }
          break
        case "EnCour":
          this.enCourMaintenance = resultatTrie
          switch (colonne) {
            case "Prioriter":
              this.triePrioriter[1] = true
              this.trieMachine[1] = false
              this.trieDate[1] = false
              break
            case "Date":
              this.triePrioriter[1] = false
              this.trieMachine[1] = false
              this.trieDate[1] = true
              break
            case "Machine":
              this.triePrioriter[1] = false
              this.trieMachine[1] = true
              this.trieDate[1] = false
              break
          }
          break
        case "Terminer":
          this.demandeTerminer = resultatTrie
          switch (colonne) {
            case "Prioriter":
              this.triePrioriter[2] = true
              this.trieMachine[2] = false
              this.trieDate[2] = false
              break
            case "Date":
              this.triePrioriter[2] = false
              this.trieMachine[2] = false
              this.trieDate[2] = true
              break
            case "Machine":
              this.triePrioriter[2] = false
              this.trieMachine[2] = true
              this.trieDate[2] = false
              break
          }
          break
      }
    },
    trierPrioriter(listeDemande: DemandeMaintenance[]) {
      return listeDemande.sort((a: DemandeMaintenance, b: DemandeMaintenance) => {
        if (a.getPriorite() == "E" && b.getPriorite() != "E") return -1
        else if (a.getPriorite() != "E" && b.getPriorite() == "E") return 1
        else if (a.getPriorite() == "M" && b.getPriorite() != "E" && b.getPriorite() != "M") return -1
        else if (b.getPriorite() == "M" && (a.getPriorite() == "E" || a.getPriorite() == "M")) return 1
        else if (a.getPriorite() == "F" && (b.getPriorite() == "E" || b.getPriorite() == "M")) return 1
        else if (b.getPriorite() == "F" && (a.getPriorite() == "E" || a.getPriorite() == "M")) return 1
        else return 0
      })
    },
    trierDate(listeDemande: DemandeMaintenance[]) {
      return listeDemande.sort((a: DemandeMaintenance, b: DemandeMaintenance) => {
        const date1 = new Date(a.getDate())
        const date2 = new Date(b.getDate())
        if (date1 > date2) return 1
        else if (date1 < date2) return -1
        else return 0
      })
    },
    trierMachine(listeDemande: DemandeMaintenance[]) {
      return listeDemande.sort((a: DemandeMaintenance, b: DemandeMaintenance) => {
        const machine1 = this.chercherNomMachine(a.getIDMachine())
        const machine2 = this.chercherNomMachine(b.getIDMachine())
        if (machine1 > machine2) return 1
        else if (machine1 < machine2) return -1
        else return 0
      })
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Tableau de bord", //0
            "Demande de maintenance", //1
            "Priorité", //2
            "Machine", //3
            "Date de demande", //4
            "Panne", //5
            "Maintenance en cours", //6
            "Nouvelle demande de maintenance", //7
            "Horamètre", //8
            "Opérateur", //9
            "État de la demande", //10
            "Maintenance", //11
            "Temps passé", //12
            "Réparation", //13
            "Emplacement du pneu sélectionné", //14
            "Aucun", //15
            "Pneu monté", //16
            "Pneu démonté", //17
            "État", //18
            "Enregistrer", //19
            "Une erreur est survenue lors de l'enregistrement des données. Veuillez en informer le responsable.", //20
            "Une erreur est survenue, les données n'ont pas été récupérées.", //21
            "Réparation pneu", //22
            "A terminer", //23
            "Terminer maintenance", //24
            "Date de clôture" //25
          ]
          this.selectPriorite = [
            { text: "Faible", value: "F" },
            { text: "Moyen", value: "M" },
            { text: "Elever", value: "E" }
          ]
          this.selectEtatDemande = [
            { text: "Déclarée", value: "D" },
            { text: "En cours", value: "C" },
            { text: "Terminé", value: "T" }
          ]
          this.selectPneuDemonte = [
            { text: "HS", value: "H" },
            { text: "Défectueux", value: "D" },
            { text: "Usagé", value: "U" }
          ]
          this.selectPneuMonte = [
            { text: "Neuf", value: "N" },
            { text: "Réchappé", value: "C" },
            { text: "Réparé", value: "R" },
            { text: "Usagé", value: "U" }
          ]
          this.dialogueTabItems = ["Autre", "Pneu"]
          break
        case "AL":
          this.dictionnaire = [
            "Anzeiger", //0
            "Wartungsbedarf", //1
            "Priorität", //2
            "Maschine", //3
            "Antragsdatum", //4
            "Ausfall", //5
            "Aktuellen Wartungsvertrag", //6
            "Neue Anfrage für die Wartung", //7
            "Betriebsstunden", //8
            "Mitarbeiter", //9
            "Stand der Nachfrage", //10
            "Instandhaltung", //11
            "Stunden gesamt", //12
            "Reparatur", //13
            "Lage des ausgewählten Reifens", //14
            "Keine", //15
            "Reifen demontiert", //16
            "Reifen montiert", //17
            "Zustand", //18
            "Speichern", //19
            "Beim Speichern der Daten ist ein Fehler aufgetreten. Bitte informieren Sie den Verantwortlichen darüber.", //20
            "Es ist ein Fehler aufgetreten, die Daten wurden nicht abgerufen.", //21
            "Reifenreparatur", //22
            "Zu beenden", //23
            "Wartung beenden", //24
            "Einsendeschluss" //25
          ]
          this.selectPriorite = [
            { text: "Niedrige", value: "F" },
            { text: "Mittlere", value: "M" },
            { text: "Hohe", value: "E" }
          ]
          this.selectEtatDemande = [
            { text: "Erstellt", value: "D" },
            { text: "In Bearbeitung", value: "C" },
            { text: "Abgeschlossen", value: "T" }
          ]
          this.selectPneuDemonte = [
            { text: "Schrott", value: "H" },
            { text: "Defekt", value: "D" },
            { text: "Gebraucht", value: "U" }
          ]
          this.selectPneuMonte = [
            { text: "Neu", value: "N" },
            { text: "Runderneuert", value: "C" },
            { text: "Repariert", value: "R" },
            { text: "Gebraucht", value: "U" }
          ]
          this.dialogueTabItems = ["Andere", "Reifen"]
      }
    },
    autMaintenance() {
      return variables.autorisation.includes("Maintenance")
    }
  },
  created() {
    this.lePremierJour = sqlDate(this.premierJourSemaine(new Date()))
    this.leDernierJour = sqlDate(this.dernierJourSemaine(new Date()))
    this.initialisation()
    this.choixLangue(this.$store.state.langue)
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.choixLangue(this.$store.state.langue)
          break
        case "majPneu":
          this.pneuSelectionner(this.$store.state.pneu)
          break
      }
    })
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
